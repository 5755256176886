import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [0,3];

export const dictionary = {
		"/": [5],
		"/(protected)/account": [10,[3]],
		"/(auth)/auth": [~6,[2]],
		"/(auth)/auth/code": [~7,[2]],
		"/(public)/company": [29,[4]],
		"/(public)/contact": [30,[4]],
		"/(protected)/dashboard": [~11,[3]],
		"/(auth)/error": [8,[2]],
		"/(protected)/exploits": [12,[3]],
		"/(protected)/logout": [~13,[3]],
		"/(protected)/notifications": [~14,[3]],
		"/(public)/pricing": [31,[4]],
		"/(public)/privacy": [32,[4]],
		"/(protected)/products": [15,[3]],
		"/(protected)/products/[productSlug]": [~16,[3]],
		"/(public)/product/aggregation": [33,[4]],
		"/(public)/product/api": [34,[4]],
		"/(public)/product/collaboration": [35,[4]],
		"/(public)/product/notifications": [36,[4]],
		"/(public)/product/queries": [37,[4]],
		"/(public)/product/reporting": [38,[4]],
		"/(public)/refunds": [39,[4]],
		"/(protected)/sources/[sourceSlug]": [~17,[3]],
		"/(protected)/sources/[sourceSlug]/[vulnId]": [~18,[3]],
		"/(protected)/tags/[tagId]": [~19,[3]],
		"/(protected)/team": [~20,[3]],
		"/(protected)/team/create": [21,[3]],
		"/(protected)/team/integrations": [~22,[3]],
		"/(protected)/team/settings": [~23,[3]],
		"/(public)/terms": [40,[4]],
		"/(protected)/vendors/[vendorSlug]": [~24,[3]],
		"/(auth)/verify-request": [~9,[2]],
		"/(protected)/vulnerabilities": [~25,[3]],
		"/(protected)/vulnerabilities/[vulnId]": [~26,[3]],
		"/(protected)/weaknesses": [27,[3]],
		"/(protected)/weaknesses/[slug]": [28,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';